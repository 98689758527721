import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './defected.css';

const DefectedMissingItems = () => {
  const [reportedItems, setReportedItems] = useState([]);

  const fetchDefectedMissingItems = async () => {
    try {
      const response = await axios.get('https://teampavan.de/api/owner/get_missing_damaged_products');
      setReportedItems(response.data.reported_items);
    } catch (error) {
      console.error('Error fetching defected and missing items:', error);
    }
  };

  useEffect(() => {
    fetchDefectedMissingItems();
  }, []);

  return (
    <div className="defectedTableContainer">
      {/* <h2>Defected and Missing Items</h2> */}
      {reportedItems.length > 0 ? (
        <table className="defectedTable">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Status</th>
              <th>Reported By</th>
              <th>Apartment</th>
              <th>Room</th>
              <th>Reported At</th>
            </tr>
          </thead>
          <tbody>
            {reportedItems.map((item, index) => (
              <tr key={index}>
                <td>{item.item_name}</td>
                <td>{item.quantity}</td>
                <td>{item.status}</td>
                <td>{item.reported_by}</td>
                <td>{item.apartment}</td>
                <td>{item.room}</td>
                <td>{item.reported_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No defected or missing items reported.</p>
      )}
    </div>
  );
};

export default DefectedMissingItems;
