import React, { useEffect } from 'react'
import Header from './Header'
import MainApplication from './MainApplication'
import { useNavigate } from 'react-router-dom'

const CompleteMainApp = () => {

  const navigate = useNavigate()
  useEffect(()=>{
    if(!sessionStorage.getItem("user-data")){
      navigate("/")
    }
  
  },[navigate])
  return (
    <>
      <Header />
      <MainApplication/>
    </>
  )
}

export default CompleteMainApp
