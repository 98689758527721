import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import Stack from "./utlis/stack";
import {  useLocation } from "react-router-dom";
import axios from "axios";

// Create a Context for the theme
const AppContext = createContext();

// Create a custom hook to use the AppContext
export const useApp = () => {
  return useContext(AppContext);
};

const API_ENDPOINT = "https://teampavan.de/api";
const MEDIA_ENDPOINT = "https://teampavan.de"

// Create a Provider component
export const AppProvider = ({ children }) => {
  let navigationStack = useRef(new Stack());
  const [user, setUser] = useState(null);
  const [headerNavigation, setHeaderNavigation] = useState([]);
  const [modalActive, setModalActive] = useState(null);
  const [items, setItems] = useState([]);
  const [apartments, setApartments] = useState([]);
  const [workers, setWorkers] = useState([]);
  const location = useLocation();
  const initialNavigation = location.pathname.split("/").slice(1);
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if(sessionStorage.getItem("user-data")){
      
      setUser(JSON.parse(sessionStorage.getItem("user-data")).user)
      setIsAdmin(!(JSON.parse(sessionStorage.getItem("user-data")).user.role === "Worker"));
      console.log(!(JSON.parse(sessionStorage.getItem("user-data")).user.role === "Worker"))
    }
    if (
      location.pathname !== "/" &&
      navigationStack.current.peek() !==
        initialNavigation[initialNavigation.length - 1]
    ) {
      initialNavigation.forEach((ele) => {
        !navigationStack.current.items.includes(`/${ele}`) &&
          navigationStack.current.push(`/${ele}`);
      });
      setHeaderNavigation([...navigationStack.current.items]);

     
    }
    // eslint-disable-next-line 
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/owner/get_all_items`);

      setItems(response.data);
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };


  const fetchWorkers = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/owner/get_all_workers`);
      setWorkers(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllApartments = async () => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/owner/get_all_apartments`
      );
      setApartments(response.data)
    } catch (error) {
      console.error("Error fetching apartments:", error);
      return []; // Return an empty array in case of an error
    }
  };
  // setHeaderNavigation(arr);

  const getAllottedApartments = async () => {
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id
    try {
      if (!userId) {
        console.log("No User Id Present")
        return;
      }
      if(!(JSON.parse(sessionStorage.getItem("user-data")).user.role === "Worker")){
        return
      }
      const response = await axios.get(`${API_ENDPOINT}/worker/alloted_apartments`, {
        headers: {
          'User-id': userId
        }
      });
      
      setApartments(response.data.apartments)
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.error);
      } else {
        console.log('An unexpected error occurred');
      }
    }
  };
  
  

  return (
    <AppContext.Provider
      value={{
        navigationStack: navigationStack.current,
        headerNavigation,
        setHeaderNavigation,
        API_ENDPOINT,
        modalActive,
        setModalActive,
        fetchItems,
        fetchAllApartments,
        items,setIsAdmin,
        setItems,setApartments,apartments,setUser,
        fetchWorkers,workers, setWorkers,isAdmin,getAllottedApartments,user,MEDIA_ENDPOINT
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
