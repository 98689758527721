import React, { useState } from "react";
import { useApp } from "../../../Context/Context";

const AddItemsModal = () => {
  // Define state for the inputs
  const {  setModalActive,API_ENDPOINT ,setItems} = useApp();
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setFile(null);
      setFileName("No file chosen");
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    // Create a form data object for multipart/form-data
    const formData = new FormData();
    formData.append("item_name", name);
    formData.append("quantity", quantity);
    formData.append("item_photo", file);
  
    // Log form data to debug
    console.log({
      name,
      quantity,
      file,
    });
  
    // Simulate API call (replace with actual API request)
    fetch(`${API_ENDPOINT}/owner/add_item`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
  
        // Add new item to the existing items state
        setItems((prevItems) => [
          ...prevItems,
          {
            item_id: data.item_id, // Assuming API returns the item_id
            item_name: data.item_name || name, // Use name from API or input
            quantity: data.quantity || quantity, // Use quantity from API or input
            item_photo: data.item_photo || file, // Use photo from API or input
          }
        ]);
  
        // Close the modal after successful submission
        setModalActive(null);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  

  return (
    <>
      <div className="modalContentInner">
        <h3>Add Item</h3>
        <div className="inputContainer">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="number"
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          <div className="file-input-container">
            <input
              type="file"
              id="file"
              className="file-input"
              onChange={handleFileChange}
            />
            <label htmlFor="file" className="file-label">
              Choose File
            </label>
            <span className="file-name">{fileName}</span>
          </div>
        </div>
        <button className="modalBtn" onClick={handleSubmit}>
          Allot
        </button>
      </div>
    </>
  );
};

export default AddItemsModal;
