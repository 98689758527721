import React, { useEffect, useState } from "react";
import { useApp } from "../../../Context/Context";
import { useNavigate, useParams } from "react-router-dom";
import SelectItemsCard from "../Worker/SelectItemsCard";
import axios from "axios";

const WorkerMainFlow = () => {
  const { API_ENDPOINT, navigationStack, setHeaderNavigation } = useApp();
  // const [items, setItems] = useState([]);
  const [apartment, setApartment] = useState("");
  const [roomName, setRoomName] = useState("");
  let { roomId, apartmentName } = useParams();
  roomId = roomId.split("-")[1];
  const [items1, setItems1] = useState([]);
  const [state, setState] = useState({
    damageState: true,
    missingState: false,
    collectState: false,
    allotState: false,
    checkList: false,
  });
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const flag = items1.some((item) => item.selected);
    setSelected(flag)
  }, [items1]);
  

  const fetchItems1 = async () => {
    const url = `${API_ENDPOINT}/worker/get_items_allotted_to_room?apartment_id=${apartmentName}&room_id=${roomId}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "User-id": JSON.parse(sessionStorage.getItem("user-data")).user_id,
        },
      });

      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error);
      }
      const data = await response.json();
      console.log(data.items);
      let allotedItems = data.items;
      // setItems(data.items);
      if (allotedItems) {
        let mappingItems = [];

        allotedItems.forEach((item) => {
          let item1 = {
            ...item,
            value: 1,
            selected: false,
          };
          if (item1.quantity_allotted > 0) {
            mappingItems.push(item1);
          }
        });
        console.log(mappingItems);
        setItems1(mappingItems);
      }
      setApartment(data.apartment);
      setRoomName(data.room);
    } catch (err) {
      console.log(err); // Handle any errors
    }
  };

  const fetchWorkerAllotedItems = async function () {
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;
    try {
      if (!userId) {
        console.log("User Not Login");
      }
      const response = await axios.get(
        `${API_ENDPOINT}/worker/get_items_allotted_to_user`,
        {
          headers: {
            "User-id": userId, // Pass the user ID in the request header
          },
        }
      );
      console.log(response.data.allotted_items);
      let allotedItems = response.data.allotted_items;
      // setItems(data.items);
      if (allotedItems) {
        let mappingItems = [];

        allotedItems.forEach((item) => {
          let item1 = {
            ...item,
            value: 0,
            selected: false,
          };
          if (item1.quantity > 0) {
            mappingItems.push(item1);
          }
        });
        console.log(mappingItems);
        setItems1(mappingItems);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("User not found.");
      } else {
        console.log("An error occurred while fetching the items.", err);
      }
    }
  };

  const markDamage = async () => {
    // Replace with actual user ID
    let st = { ...state, missingState: true };
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
        object: "Damaged",
      }));

    if(selectedItems.length === 0){
      await fetchItems1()
      setState(st);
      return;
    }

    const payload = {
      apartment_id: Number(apartmentName),
      room_id: Number(roomId),
      items: selectedItems,
    };
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;

    // Data to be sent in the body of the request

    try {
      // Making the POST request
      const response = await axios.post(
        `${API_ENDPOINT}/worker/collect_damaged_missing_items`,
        payload,
        {
          headers: {
            "User-Id": userId,
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      await fetchItems1()
    } catch (error) {
      // Handle errors
      console.error(
        "Error reporting items:",
        error.response ? error.response.data : error.message
      );
    }

    console.log(st);
    setState(st);
  };

  const markMissing = async () => {
     // Replace with actual user ID
     let st = { ...state, collectState: true };
     let selectedItems = items1
       .filter((item) => item.selected === true)
       .map((item) => ({
         item_id: item.item_id,
         quantity: Number(item.value),
         object: "Missing",
       }));

       if(selectedItems.length === 0){
        await fetchItems1()
        setState(st);
        return;
      }
 
     const payload = {
       apartment_id: Number(apartmentName),
       room_id: Number(roomId),
       items: selectedItems,
     };
     let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;

     try {
       // Making the POST request
       const response = await axios.post(
         `${API_ENDPOINT}/worker/collect_damaged_missing_items`,
         payload,
         {
           headers: {
             "User-Id": userId,
           },
         }
       );
 
       // Handle the response
       console.log("Response:", response.data);
       await fetchItems1()
     } catch (error) {
       // Handle errors
       console.error(
         "Error reporting items:",
         error.response ? error.response.data : error.message
       );
     }
    console.log(st);
    setState(st);
  };

  const handleCollect = async () => {
    let st = { ...state, allotState: true };
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
      }));
      if(selectedItems.length === 0){
        await  fetchWorkerAllotedItems()
        setState(st);
        return;
      }

    const payload = {
      apartment_id: Number(apartmentName),
      room_id: Number(roomId),
      items: selectedItems,
    };
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/worker/collect_back_item`,
        payload,
        {
          headers: {
            "User-id": userId,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Success:", response.data);
      if (response.status === 200) {
        // await fetchItems1();
      }
      fetchWorkerAllotedItems();
    } catch (error) {
      console.log("Error allotting items to worker:", error);
      // Add any error handling or UI updates here
    }

    setState(st);
  };

  const handleAllot = async () => {
    let st = { ...state, checkList: true };
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
      }));

    const payload = {
      apartment_id: Number(apartmentName),
      room_id: Number(roomId),
      items: selectedItems,
    };
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/worker/allot_items_to_room`,
        payload,
        {
          headers: {
            "User-id": userId,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Success:", response.data);
      if (response.status === 200) {
        // await fetchItems1();
      }
      fetchItems1();
    } catch (error) {
      console.log("Error allotting items to worker:", error);
      // Add any error handling or UI updates here
    }

    setState(st);
  };

  const handleSubmit = async () => {
    navigateToApart();
  };

  const navigateToApart = async function () {
    navigationStack.pop();
    setHeaderNavigation([...navigationStack.items]);
    navigate(navigationStack.generateLink(navigationStack.peek()));
  };

  useEffect(() => {
    fetchItems1();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="WorkerMainFlowMainDiv">
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <h2> </h2>
          <div className="workerHeaderInfo">
            <ul>
              <li>
                Apartment Name - <span>{apartment}</span>
              </li>
              <li>
                Room Name - <span>{roomName}</span>
              </li>
            </ul>
          </div>
        </span>
        <div className="flowContainer">
          <div className={`flowss ${state.damageState && "active-state"} `}>
            {state.damageState && <div className="activeCircle "></div>}
            Damage Items
          </div>
          <div className={`flowss ${state.missingState && "active-state"} `}>
            {state.missingState && <div className="activeCircle "></div>}
            MissingItems
          </div>
          <div className={`flowss ${state.collectState && "active-state"} `}>
            {state.collectState && <div className="activeCircle "></div>}Collect
            Items
          </div>
          <div className={`flowss ${state.allotState && "active-state"} `}>
            {state.allotState && <div className="activeCircle "></div>}Allot
            Items
          </div>
          <div className={`flowss ${state.checkList && "active-state"} `}>
            {state.checkList && <div className="activeCircle "></div>}Checklist
          </div>
        </div>
        <div className="itemsContiner">
          {items1.map((item) => {
            return (
              <SelectItemsCard
                items1={items1}
                setItems1={setItems1}
                item={item}
                key={item.item_id}
              />
            );
          })}
        </div>
        <div className="BtnConatiner">
          {!state.missingState && (
            <button
              className="redBtn"
              onClick={() => {
                markDamage();
              }}
            >
              {selected ?"Mark Damage":"No Damage"}
            </button>
          )}
          {state.missingState && !state.collectState && (
            <button
              className="redBtn"
              onClick={() => {
                markMissing();
              }}
            >
               {selected ?"Mark Missing":"No Missing"}
            </button>
          )}
          {state.missingState && state.collectState && !state.allotState && (
            <button
              className="blueBtn"
              onClick={() => {
                handleCollect();
              }}
            >
              {selected ?"Collect":"No Collect"}
            </button>
          )}
          {state.missingState &&
            state.collectState &&
            state.allotState &&
            !state.checkList && (
              <button
                className="blueBtn greenBtn"
                onClick={() => {
                  handleAllot();
                }}
              >
                {selected ?"Allot":"No Allot"}
              </button>
            )}
          {state.missingState &&
            state.collectState &&
            state.allotState &&
            state.checkList && (
              <button
                className="blueBtn"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Check
              </button>
            )}
        </div>
      </div>
    </>
  );
};

export default WorkerMainFlow;
