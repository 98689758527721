import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import arrow from "../../CommonIcons/itemArr.svg";
import "./workerPage.css";
import SelectItemsCard from "./SelectItemsCard";
import { useApp } from "../../../Context/Context";
import axios from "axios";

const ItemsMainPage = () => {
  const { workerName } = useParams();

  const { fetchItems, items, workers, fetchWorkers, API_ENDPOINT } =
    useApp();
  const location = useLocation();
  let isRecieve = location.pathname.includes("/receive-items");
  const [items1, setItems1] = useState([]);
  const [worker, setWorker] = useState({});
  
  const fetchWorkerAllotedItems = async function () {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/worker/get_items_allotted_to_user`,
        {
          headers: {
            "User-id": worker.user_id, // Pass the user ID in the request header
          },
        }
      );
      console.log(response.data.allotted_items);
      let allotedItems = response.data.allotted_items;
      if(allotedItems){
        let mappingItems = [];

        allotedItems.forEach((item) => {
        let item1 = {
          ...item,
          value: 0,
          selected: false,
        };
        if (item1.quantity > 0) {
          mappingItems.push(item1);
        }
      });
      setItems1(mappingItems);
      }
      // setItems(response.data.allotted_items); // Store the allotted items in state
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("User not found.");
      } else {
        console.log("An error occurred while fetching the items.", err);
      }
    }
  };

  useEffect(() => {
    fetchWorkers();
    if (!isRecieve) {
      fetchItems();
      console.log("working")
    }
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    let workerr = workers.find((worker) => worker.id === Number(workerName));
    setWorker(workerr);
    if (isRecieve && worker && worker.id) {
      fetchWorkerAllotedItems();
    }
    // eslint-disable-next-line 
  }, [workers]);

  useEffect(() => {
    if (!isRecieve) {
      let mappingItems = [];

      items.forEach((item) => {
        let item1 = {
          ...item,
          value: 0,
          selected: false,
        };
        if (item1.quantity > 0) {
          mappingItems.push(item1);
        }
      });
      setItems1(mappingItems);
      console.log(mappingItems)
    }
    
    // eslint-disable-next-line 
  }, [items]);

  

  const allotItemsToWorker = async () => {
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
      }));

    console.log(selectedItems);

    const payload = {
      worker_id: Number(worker.id),
      items: selectedItems,
    };

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/owner/allot_item_to_worker`,
        payload
      );
      console.log("Success:", response.data.message);
      if (response.status === 200) {
        await fetchItems();
      }
    } catch (error) {
      // Handle error
      console.error("Error allotting items to worker:", error);
      // Add any error handling or UI updates here
    }
  };


  const recieveItemsfromWorker = async () => {
    let selectedItems = items1
      .filter((item) => item.selected === true)
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
      }));

    console.log(selectedItems);

    const payload = {
      worker_id: Number(worker.id),
      items: selectedItems,
    };

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/owner/recieve_items`,
        payload
      );
      console.log("Success:", response.data.message);
      if (response.status === 200) {
        // console.log("yes");
        await fetchWorkerAllotedItems();
      }
    } catch (error) {
      // Handle error
      console.error("Error allotting items to worker:", error);
      // Add any error handling or UI updates here
    }
  };

  return (
    <div className="majorDisplayMainDiv homeItemsMainDiv ">
      {worker && (
        <>
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>
              Select Items <img alt="arrow" src={arrow} />
            </h2>
            <div className="workerHeaderInfo">
              <ul>
                <li>
                  Worker Id - <span>{worker.id}</span>
                </li>
                <li>
                  Worker Name - <span>{worker.name}</span>
                </li>
              </ul>
            </div>
          </span>
          <div className="selectedItemsContainer cardsContiner ">
            <>
              {items1.length !== 0  ? items1.map((item) => {
                return (
                  <SelectItemsCard
                    items1={items1}
                    setItems1={setItems1}
                    item={item}
                    key={item.item_id}
                  />
                )
              })
              : <p>No Items To {isRecieve ? "Receive":"Allot"}</p>}
            </>
            <div className="BtnConatiner">
              {isRecieve ? (
                <button className="SelectedItemsReciveBtn" onClick={()=>{
                  recieveItemsfromWorker()
                }}>Receive</button>
              ) : (
                <button
                  className="SelectedItemsAllotBtn"
                  onClick={() => {
                    allotItemsToWorker();
                  }}
                >
                  Allot
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ItemsMainPage;
