import React from "react";
import "./common.css";
import logo from "./Icons/pavanLogo.svg";
import profile from "./Icons/pavanProfile.svg";
import HeaderNavigation from "./HeaderNavigation";
import { useApp } from "../../../Context/Context";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const {navigationStack,setHeaderNavigation} = useApp()

  const navigate = useNavigate();
  return (
    <>
      <div className="headerMainDiv">
        <div className="headerLogo">
          <img alt="logo" src={logo} />
        </div>
        <div className="otherLogo">
          
          <div onClick={()=>{
            while(navigationStack.peek() !== "/home"){
              navigationStack.pop()
            }
            navigationStack.push("/profile");
            setHeaderNavigation([...navigationStack.items]);
            navigate(navigationStack.generateLink(`/profile`));
          }}>
            <img alt="profileLogo" src={profile}/>
          </div>
        </div>
      </div>
      <HeaderNavigation/>
    </>
  );
};

export default Header;
