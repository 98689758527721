import React from 'react'
import { useApp } from '../../../Context/Context';
import { useNavigate } from 'react-router-dom';

const RoomCard = ({room}) => {
  const {
  setHeaderNavigation,
  navigationStack,
} = useApp();

const navigate = useNavigate();




  const navigateToWorker = async function (worker) {
    navigationStack.push(`/${worker}`);
    setHeaderNavigation([...navigationStack.items]);
    console.log(navigationStack.generateLink(`/${worker}`))
    navigate(navigationStack.generateLink(`/${worker}`));
  };
  return (
    <>
      <div className='roomCardDIv addCards' onClick={()=>{navigateToWorker(`room-${room.room_id}`)}}>
        {room.room_name}<span>({room.number_of_bed})</span>
      </div>
    </>
  )
}

export default RoomCard
