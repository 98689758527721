import React from "react";
import imageLink from "./Icons/demoWorker.png";
import { useApp } from "../../../Context/Context";
import { useNavigate } from "react-router-dom";

const WorkerCard = ({ worker }) => {
  const {
    
    setHeaderNavigation,
    navigationStack,MEDIA_ENDPOINT
  } = useApp();
  const navigate = useNavigate();

  // let worker = {
  //   "name":worker.name,
  //   "email":"random.email.com",
  //   "password":"1234",
  //   "selectedPgs":["a","bn","c"]
  // };

  const navigateToWorker = async function (worke) {
    navigationStack.push(`/${worke}`);
    setHeaderNavigation([...navigationStack.items]);
    navigate(navigationStack.generateLink(`/${worke}`));
  };


  return (
    <>
      <div className="cardMainDIv">
        <img
          alt="workerPic"
          src={`${worker.worker_photo ?`${MEDIA_ENDPOINT}${worker.worker_photo}` :imageLink}`}
          onClick={() => navigateToWorker(worker.id)}
        />
        <div className="cardDetails">
          <p>{worker.name}</p>
        </div>
      </div>
    </>
  );
};

export default WorkerCard;
