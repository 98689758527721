import React from "react";
import { useApp } from "../../../Context/Context";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

const HeaderNavigation = () => {
  const { headerNavigation, setHeaderNavigation, navigationStack } = useApp();

  

  const stackNavigate = (ele) => {
    while (!navigationStack.isEmpty() && ele !== navigationStack.peek()) {
      navigationStack.pop();
    }
    console.log("workeing")
    setHeaderNavigation([...navigationStack.items]);
  };

  const convertLink = function (ele){
    let arrStr = ele.split("/")[1].split("-");
    ele = "";
    for(let i = 0 ; i < arrStr.length ; i++){
      ele += arrStr[i][0].toUpperCase()+arrStr[i].slice(1)+" ";
    }
    return ele;
  }

  return (
    <Container fluid="xxl" className="headerNavigationMainDiv">
      <div className="headerNavigation">

          {headerNavigation && headerNavigation.map((navigation, i) => (
            <React.Fragment key={i}>
                <Link
                  to={`${navigationStack.generateLink(navigation)}`}
                  className="navigationLink"
                  onClick={() => stackNavigate(navigation)}
                >
                  {convertLink(navigation)}
                </Link>
              {i < headerNavigation.length - 1 && " / "} 
            </React.Fragment>
          ))}
      </div>
    </Container>
  );
};

export default HeaderNavigation;
