import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./login.css";
import { useApp } from "../../../Context/Context";
import logoImg from "../../CommonIcons/pavanLogo.svg";

const Login = () => {
  const {
    navigationStack,
    setHeaderNavigation,
    API_ENDPOINT,
    setUser,
    setIsAdmin,
  } = useApp();
  const navigate = useNavigate();

  // States for form inputs
  const [isLoginTab, setIsLoginTab] = useState(true); // Switch between login and signup
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [gender, setGender] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");
  const toggleTab = () => setIsLoginTab(!isLoginTab);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://teampavan.de/api/`, {
        username,
        password,
      });

      sessionStorage.setItem("user-data", JSON.stringify(response.data));
      setUser(response.data.user);
      setIsAdmin(!(response.data.user.role === "Worker"));
      // Handle navigation after successful login
      navigationStack.push("/home");
      setHeaderNavigation([...navigationStack.items]);
      navigate("/home");
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
  
    formData.append("username", username);
    formData.append("password", password);
    formData.append("email", email);
  
    if (phone) formData.append("phone", phone);
    if (name) formData.append("name", name); 
  
    if (addressLine1) formData.append("address_line_1", addressLine1);
    if (addressLine2) formData.append("address_line_2", addressLine2);
    if (city) formData.append("city", city);
    if (state) formData.append("state", state);
    if (postalCode) formData.append("postal_code", postalCode);
  
    if (gender) formData.append("gender", gender);
  
    if (file) formData.append("worker_photo", file);
  
    try {
      const response = await axios.post(`${API_ENDPOINT}/user/signup`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      console.log("Signup successful:", response.data);
      if(response.status === 201){
        sessionStorage.setItem("user-data",response.data);
        setUser(response.data.user);
        setIsAdmin(!(response.data.user.role === "Worker"));
        navigationStack.push("/home");
        setHeaderNavigation([...navigationStack.items]);
        navigate("/home");
      }
    } catch (error) {
      console.error("Signup failed:", error.response ? error.response.data : error);
    }
  };
  

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setFile(null);
      setFileName("No file chosen");
    }
  };

  return (
    <div className="login-container">
      <div className="loginHeader">
        <img alt="logo" src={logoImg} />
      </div>
      <div className="completetabsContainer">
        <div className="tab-container">
          <button onClick={toggleTab} className={isLoginTab ? "active" : ""}>
            Login
          </button>
          <button onClick={toggleTab} className={!isLoginTab ? "active" : ""}>
            Signup
          </button>
        </div>

        {isLoginTab ? (
          // Login Form
          <form onSubmit={handleLoginSubmit} className="loginSingUP">
            <div className="input-group">
              <input
                type="text"
                id="username"
                placeholder="User Name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
        ) : (
          // Signup Form
          <form onSubmit={handleSignupSubmit} className="loginSingUP">
            <div className="input-group inp2">
              <input
                type="text"
                id="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                id="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <input
                type="email"
                id="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="input-group">
              <input
                type="text"
                id="addressLine1"
                placeholder="Address Line 1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                id="addressLine2"
                placeholder="Address Line 2"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
            </div>
            <div className="input-group inp2">
              <input
                type="text"
                id="city"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <input
                type="text"
                id="state"
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>

            <div className="input-group inp2">
              <input
                type="text"
                id="postalCode"
                placeholder="Postal Code"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
              <select
                id="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="input-group inp2">
              <input
                type="tel"
                id="phone"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="file-input-container">
            <input
              type="file"
              id="file"
              className="file-input"
              onChange={handleFileChange}
            />
            <label htmlFor="file" className="file-label">
              Choose Your Picture
            </label>
            <span className="file-name">{fileName}</span>
          </div>
            <button type="submit">Signup</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
