import React, { useEffect, useState } from "react";
import arrow from "../../CommonIcons/itemArr.svg";
import { useApp } from "../../../Context/Context";
import ItemCard from "../HomePage/ItemCard";
import { useParams } from "react-router-dom";

const ItemsRoomHave = () => {
  const { API_ENDPOINT } = useApp();
  const [items, setItems] = useState([]);
  const [apartment, setApartment] = useState("");
  const [roomName, setRoomName] = useState("");
  let { roomId, apartmentName } = useParams();
  console.log(roomId.split("-")[1]);

  const fetchItems = async () => {
    const url = `${API_ENDPOINT}/worker/get_items_allotted_to_room?apartment_id=${apartmentName}&room_id=${
      roomId.split("-")[1]
    }`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "User-id": JSON.parse(sessionStorage.getItem("user-data")).user_id,
        },
      });

      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error);
      }
      const data = await response.json();
      setItems(data.items);
      setApartment(data.apartment);
      setRoomName(data.room);
      console.log(data);
    } catch (err) {
      console.log(err); // Handle any errors
    }
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line
  }, []);

  console.log(apartmentName + " " + roomId);

  return (
    <>
      <div className="ItemsRoomHaveMainDiv">
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>
            Items Have
            <img alt="arrow" src={arrow} />
          </h2>
          <div className="workerHeaderInfo">
            <ul>
              <li>
                Apartment Name - <span>{apartment}</span>
              </li>
              <li>
                Room Name - <span>{roomName}</span>
              </li>
            </ul>
          </div>
        </span>
        <div className="itemsContiner">
          {items.map((item, index) => (
            <ItemCard item={item} key={item.item_id} editable={false} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ItemsRoomHave;
