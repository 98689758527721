import React, { useEffect, useState } from "react";
import { useApp } from "../../../Context/Context";

const EditApartmentModal = ({ apartment }) => {
  // Define state for the inputs
  const {
    setModalActive,
    fetchWorkers,
    workers,
    API_ENDPOINT,
    setApartments,
    apartments,
    fetchAllApartments
  } = useApp();
  const [name, setName] = useState(apartment.name_of_property);
  const [address, setAddress] = useState(apartment.address);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");
  const [selectedWorker, setSelectedWorker] = useState(0);

  const handleWorkerChange = (event) => {
    setSelectedWorker(event.target.value); // Update state when worker is selected
    console.log("Selected Worker ID:", event.target.value);
  };

  useEffect(() => {
    fetchWorkers();
    if (apartment.worker) {
      setSelectedWorker(apartment.worker);
    }
    // eslint-disable-next-line
  }, []);

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setFile(null);
      setFileName("No file chosen");
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Create a form data object if you want to send files as multipart/form-data
    const formData = new FormData();
    formData.append("name_of_property", name);
    formData.append("address", address);
    if (file) formData.append("apartment_photo", file);
    if (selectedWorker !== 0) formData.append("worker", selectedWorker);

    // Post the data (Here you can replace it with your API call logic)
    console.log({
      name,
      address,
      file,
    });

    try {
      // Make the POST request
      const response = await fetch(
        `${API_ENDPOINT}/owner/edit_apartment/${apartment.apartment_id}`,
        {
          method: "PATCH",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();

        setApartments((prevApartments) => {
          const updatedApartments = prevApartments.map((prevApartment) => {
              if (prevApartment.apartment_id === data.apartment_id) {
                  return {
                      ...prevApartment,
                      name_of_property: data.name_of_property,
                      apartment_id: data.apartment_id,
                      address: data.address,
                      apartment_photo: data.apartment_photo,
                      worker: data.worker,
                  };
              }
              return prevApartment;
          });
          return updatedApartments;
      });
      fetchAllApartments()
        console.log(apartments)


        setApartments([...apartments, data]);
        console.log("Apartment added successfully:", data);
      } else {
        console.error("Error adding apartment:", response.statusText);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
    setModalActive(null);
  };

  return (
    <>
      <div className="modalContentInner">
        <h3>Edit Apartment</h3>
        <div className="inputContainer">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <div className="file-input-container">
            <input
              type="file"
              id="file"
              className="file-input"
              onChange={handleFileChange}
            />
            <label htmlFor="file" className="file-label">
              Choose File
            </label>
            <span className="file-name">{fileName}</span>
          </div>
          <select
            className="modalSelect"
            value={selectedWorker}
            onChange={handleWorkerChange}
          >
            <option className="option" value={0}>
              Select Worker
            </option>
            {workers.map((worker) => (
              <option key={worker.id} className="option" value={worker.id}>
                {worker.name}
              </option>
            ))}
          </select>
        </div>
        <button className="modalBtn" onClick={handleSubmit}>
          Edit
        </button>
      </div>
    </>
  );
};

export default EditApartmentModal;
